<script setup lang="ts">
import { ChevronUpIcon } from '@heroicons/vue/24/outline'

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}
</script>

<template>
  <Padding>
    <Container class="mx-auto items-end">
      <button class="mb-12 mt-6" @click="() => scrollToTop()">
        <ChevronUpIcon class="h-20 w-20 text-elbworx-yellow" />
      </button>
    </Container>
  </Padding>
</template>
